module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LIFEFORMS","short_name":"LIFEFORMS","start_url":"/","icon":"static/images/logo.png","icon_options":{"purpose":"any maskable"},"display":"standalone","crossOrigin":"use-credentials","background_color":"#ffffff","theme_color":"#000000","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"5e90dbf4bc8eda1f1e402991326df1c9"},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
